import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Selection from '../components/section.2.js'
import SelectionNext from '../components/section.3.js'
import Layout from '../components/Layout'

const RoomsPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <div className="Hero">
      <div className="HeroGroup">
        <h1>
          <FormattedMessage id="OurRooms" />
        </h1>
      </div>
    </div>
    <div className="intro">
      <h2>
        <FormattedMessage id="OurRoomstext" />
      </h2>
      <p className="IntroServices">
        <FormattedMessage id="OurRoomsIntro" />
      </p>
    </div>

    <Selection
      title={<FormattedMessage id="Roomstwobedstitle" />}
      image={require('../Images/balcony.jpg')}
      imagesecond={require('../Images/couplebeds.png')}
      optionone={<FormattedMessage id="Roomstwobedsone" />}
      optiontwo={<FormattedMessage id="Roomstwobedstwo" />}
      optionthree={<FormattedMessage id="Roomstwobedsthree" />}
      textmore={<FormattedMessage id="ourlocationtextmore" />}
    />
    <Selection
      title={<FormattedMessage id="Roomstriplebedstitle" />}
      image={require('../Images/bedthree.jpg')}
      imagesecond={require('../Images/fourbeds.jpg')}
      optionone={<FormattedMessage id="Roomsthreebedsone" />}
      optiontwo={<FormattedMessage id="Roomsthreebedstwo" />}
      optionthree={<FormattedMessage id="Roomsthreebedsthree" />}
    />

    <SelectionNext
      titlefirstoption={<FormattedMessage id="Location" />}
      textfirstoption={<FormattedMessage id="OurLocationIntro" />}
      buttonfirstoption={<FormattedMessage id="Checkthelocation" />}
      link1="/location"
      titlesecondoption={<FormattedMessage id="Services" />}
      textsecondoption={<FormattedMessage id="OurServicesIntro" />}
      buttonsecondoption={<FormattedMessage id="Checktheservices" />}
      link2="/services"
    />
  </Layout>
)

RoomsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default RoomsPage
