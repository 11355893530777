import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../components/LocalizedLink'

const SectionGroup = styled.div`
  /* background: url(${props => props.image});
  background-size: cover; */
  background-color: white;
  color: #293A7E;
  display: grid;
  position:relative;
  padding-top:50px;
  padding-bottom:50px;
`

const SectionTextGroup = styled.div``
const SectionTtitleGroup = styled.div`
  display: grid;
  margin: 0 40px;
  grid-gap: 20px;
  background-color: (${props => props.image});
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }
`
const SectionTitle = styled.h3`
  color: #293a7e;
  margin: 0;
  font-size: 60px;
  line-height: 1.2;

  @media (max-width: 720px) {
    font-size: 40px;
  }
`
const SectionText = styled.div`
  color: #293a7e;

  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    margin-top: 20px;
  }
`

const SectionImageGroup = styled.div`
  margin: 0 3vw;
  overflow: hidden;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(1, 1fr);

  span {
    min-width: 0;
    overflow: hidden;
    max-height: 500px;
    position: relative;
    margin-top: 15px;
  }

  img {
    min-width: 0;
    overflow: hidden;
    margin-top: 15px;
    max-height: 1300px;
    width: 100%;
    height: 100%;
    display: grid;
    object-fit: contain;
  }

  @media (max-width: 720px) {
    margin: 6vh 3vw;
  }
`

const Section = props => (
  <SectionGroup>
    <SectionTtitleGroup>
      <SectionTextGroup>
        <SectionTitle> {props.title} </SectionTitle>
        <SectionText>
          <ul>
            <li>{props.optionone}</li>
            <li>{props.optiontwo}</li>
            <li>{props.optionthree}</li>
          </ul>
        </SectionText>
      </SectionTextGroup>
      <SectionImageGroup>
        <span>
          {' '}
          <img src={props.image} img />
        </span>
        <span>
          {' '}
          <img src={props.imagesecond} img />
        </span>{' '}
      </SectionImageGroup>
    </SectionTtitleGroup>
  </SectionGroup>
)

export default Section
